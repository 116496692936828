.tooltip {
  user-select: none;
  pointer-events: none;
  transform: translateX(-50%);
}

.tooltip-date {
  position: absolute;
  background: rgb(29, 36, 58);
  color: white;
  text-align: center;
  font-size: 10pt;
  min-width: 40px;
  padding: 4px;
  transform: translate(-50%, -500%);
}

.tooltip-value {
  position: absolute;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 10pt;
  transform: translate(-50%, -300%);
  min-width: 50px;
  padding: 5px;
  text-wrap: nowrap;
  box-shadow: 0 0 3px black;
}

.info {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.graph-container {
  height: 120px;
  margin-bottom: 30px;
}

.workoutAnalysisIntervalTable {
  border-collapse: collapse;
  column-width: auto;
  font-size: 9pt;
  width: 100%;
}

.workoutAnalysisIntervalTable tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.workoutAnalysisIntervalTable td {
  text-wrap: nowrap;
  width: 0;
  text-align: center;
  padding: 10px 10px;
}

.workoutAnalysisIntervalTable th {
  text-align: center;
  padding: 10px 10px;
  font-weight: 500;
  opacity: 0.6;
}

@media only screen and (max-width: 355px) {
  .removable-small {
    display: none;
  }
}
@media only screen and (max-width: 650px) {
  .removable {
    display: none;
  }
}

/* .header, .navbar-area{
    background-color: blue !important;
} */

@media screen and (min-width: 1000px) {
  .navbar-area {
    position: fixed;
    top: 0;
    background-color: rgba(white, white, white, 0.6);
  }
}

#linkblack {
  color: black;
}

#linkwhite {
  color: white;
}

@media screen and (max-width: 1000px) {
  #linkblack,
  #linkwhite {
    color: black;
    margin-left: 10px;
  }
}

.activity-name {
    height: 40px;
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
    font-size: 17pt;
    font-weight: 500;
    color: #3a46a7;
}

.activity-name > img {
    height: 100%;
    aspect-ratio: 1/1;
}

.activity-username {
    color: #3a46a7; 
    font-weight: 700;
    font-size: 10pt;
}
.activity-date {
    opacity: .5;
    font-weight: 400;
    font-size: 10pt;
}
@media only screen and (max-width: 355px) {
    .activity-name {
        height: 30px;
        font-size: 14pt;
    }
    
  }
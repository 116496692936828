* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  width: 100vw;
}
.fc-toolbar-title {
  display: inline; /* cheesy css trick to forcefully make the calendar's library's title inline */
  color: #3541a4 !important;
}
.fc-header-toolbar {
  margin: 0 !important;
}
.fc-toolbar-chunk {
  display: flex;
  height: 70px;
  align-items: center; /* more cheesy css to center the title */
}

.clickable-days .fc-day:not(.fc-col-header-cell):not(.fc-day:has(.fc-event)){
  cursor: pointer;
  transition: .2s;
}



// .clickable-days .fc-day:not(.fc-col-header-cell):not(.fc-day:has(.fc-event))::after {
//   content: '+';
//   width: 20px;
//   height: 20px;
//   position: absolute;
//   left: 5px;
//   top: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   background: #3788d8;
//   padding-bottom: 3px;
//   border-radius: 50%;
//   color: white;
//   opacity: 0;
//   transition: .3s;
// }
// .clickable-days .fc-day:not(.fc-col-header-cell):not(.fc-day:has(.fc-event)):hover::after {
//   opacity: 1;
// }

.clickable-days .fc-day:not(.fc-col-header-cell):not(.fc-day:has(.fc-event)):hover {
  background: #d4d4d4;
}

.fc-button-primary {
  color: black !important; /* might aswell style everything to match the original picture*/
  font-size: 20pt !important;
  background: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}
.fc-event-dragging {
  transition: none !important;
  transition: background .3s !important;
  cursor: grabbing;
  position: relative;
  overflow: hidden;
}
.fc-event-deleting {
  background: rgb(245, 72, 72) !important;	
  border: 0;
}
@keyframes spinner {
  to {transform: rotate(360deg);}
}
.fc-event-loading::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 15px;
  z-index: 100;
  height: 15px;
  margin-top: -7.5px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: transparent;
  animation: spinner .6s linear infinite;
}
.fc-event-dragging::after {
  content: 'X';
  background: red !important;
  position: absolute;
  right: 0;
  top: 0;
  display: grid;
  z-index: 100;
  color: white;
  place-items: center;
  text-align: center;
  overflow: hidden;
  transition: .3s;
  height: 100%;
  width: 0;
}
.fc-event-deleting.fc-event-dragging::after {
  width: 30px;
}
.fc-event-invisible:not(.fc-event-dragging) {
  display: none;
}
.fc-event{
  cursor: pointer;
  transition: .3s
}
.fc-event:hover{
  filter: brightness(70%);
}
.tooltip-button {
  width: 100%;
  border: 0;
  border-radius: 5px;
  color: white;
  background: #3e3e3e;
  min-width: 200px;
  height: 30px;
  margin-top: 20px;
}

.tooltip-slice {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tippy-box[data-theme~='invisible'] > .tippy-arrow::before  {
  right: 2px !important
}
.tippy-box[data-theme~='invisible'] > .tippy-arrow  {
  color: white !important;
  z-index: 1;
}
.tippy-box[data-theme~='invisible'] {
  background-color: transparent;
}
.tippy-box.hidden {
  opacity: 0;
}

@media only screen and (max-width: 470px) {
  .fc-button-primary {
  font-size: 10pt !important;
  }
  .fc-toolbar-title {
    font-size: 12pt !important;
  }
}
.map {
  display: grid;
  grid-template-rows: 300px 40px;
}

.map-helper {
  width: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-gradient {
  background: linear-gradient(
    to left,
    rgb(255, 46, 46),
    rgb(255, 217, 46),
    rgb(65, 255, 65),
    rgb(62, 62, 255)
  );
  width: 100px;
  height: 10px;
}

.summary {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  gap: 20px;
  flex-wrap: nowrap;
}

.data-value {
  font-size: 16pt;
  opacity: 0.9;
}

.data-title {
  font-size: 9pt;
  opacity: 0.5;
}

@media only screen and (max-width: 355px) {
  .data-value {
    font-size: 13pt;
  }
}
@media only screen and (max-width: 500px) {
  .summary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
